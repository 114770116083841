<template>
  <div>
    <!-- İmg-->
    <b-img
      class="aboutImg mt-0"
      :src="imgDataUrl"
      fluid
      alt="Responsive image"
    />
    <b-tabs align="center" pills class="nav-pill-secondary mt-1">
      <b-tab
        :title="getTabTitle('ERGİTME')"
        :active="isActiveTab === 'allPost'"
        class="tabButton"
      >
        <b-card-text>
          <br /><br />
          <b-row>
            <b-col md="1"></b-col>
            <b-col md="10">
              <ergitme
                v-if="groupedData && allDatas"
                :ergitmeData="groupedData[20]"
                :ergitmeSwiperData="groupedData[21]"
              ></ergitme
            ></b-col>
            <b-col md="1"></b-col>
          </b-row>
        </b-card-text>
      </b-tab>

      <b-tab class="tabButton" :title="getTabTitle('ALAŞIM')">
        <b-card-text>
          <br /><br />
          <b-row>
            <b-col md="1"></b-col>
            <b-col md="10"
              ><alasim
                v-if="groupedData && allDatas"
                :alasimData="groupedData[22]"
                :alasimSwiperData="groupedData[23]"
              ></alasim
            ></b-col>
            <b-col md="1"></b-col
          ></b-row>
        </b-card-text>
      </b-tab>

      <b-tab class="tabButton" :title="getTabTitle('DEGAZİNG ÜNİTESİ')">
        <b-card-text>
          <br /><br />
          <b-row>
            <b-col md="1"></b-col>
            <b-col md="10"
              ><degazing
                v-if="groupedData && allDatas"
                :degazingData="groupedData[24]"
                :degazingSwiperData="groupedData[25]"
              ></degazing
            ></b-col>
            <b-col md="1"></b-col
          ></b-row>
        </b-card-text>
      </b-tab>
      <b-tab class="tabButton" :title="getTabTitle('DÖKÜM')">
        <b-card-text>
          <br /><br />
          <b-row>
            <b-col md="1"></b-col>
            <b-col md="10"
              ><döküm
                v-if="groupedData && allDatas"
                :dokumData="groupedData[26]"
                :dokumSwiperData="groupedData[36]"
              ></döküm
            ></b-col>
            <b-col md="1"></b-col
          ></b-row>
        </b-card-text>
      </b-tab>
      <b-tab
        class="tabButton"
        :title="getTabTitle('HOMOJENİZASYON, NORMALİZASYON')"
      >
        <b-card-text>
          <br /><br />
          <b-row>
            <b-col md="1"></b-col>
            <b-col md="10"
              ><homoVue
                v-if="groupedData && allDatas"
                :homoData="groupedData[27]"
                :homoSwiperData="groupedData[37]"
              ></homoVue
            ></b-col>
            <b-col md="1"></b-col
          ></b-row>
        </b-card-text>
      </b-tab>
      <b-tab class="tabButton" :title="getTabTitle('KESİM ve AMBALAJ')">
        <b-card-text>
          <br /><br />
          <b-row>
            <b-col md="1"></b-col>
            <b-col md="10"
              ><kesimVue
                v-if="groupedData && allDatas"
                :kesimData="groupedData[28]"
                :kesimSwiperData="groupedData[38]"
              ></kesimVue
            ></b-col>
            <b-col md="1"></b-col
          ></b-row>
        </b-card-text>
      </b-tab>
    </b-tabs>
    <!-- Footer -->
    <footer-vue :AdressData="AdressData"> </footer-vue>
  </div>
</template>

<script>
import { BTabs, BTab, BRow, BCol, BCardText } from "bootstrap-vue";
import döküm from "./dokum/dokum.vue";
import degazing from "./degazing/degazing.vue";
import ergitme from "./ergitme/ergitme.vue";
import kesimVue from "./kesim/kesim.vue";
import alasim from "./alasim/alasim.vue";
import homoVue from "./homo/homo.vue";
import footerVue from "../home/components/footer.vue";
import axios from "axios";

export default {
  components: {
    BTabs,
    BTab,
    BRow,
    BCardText,
    BCol,
    döküm,
    degazing,
    ergitme,
    kesimVue,
    alasim,
    homoVue,
    footerVue,
  },
  data() {
    return {
      isActiveTab: "allPost",
      activeBlogId: null,
      activeAllPost: null,
      imgDataUrl: "",
      allDatas: [],
      groupedData: [],
      AdressData: [],
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    // get method
    getData() {
      // console.log("GET DATA");
      this.groupedData = [];
      const languageCode = localStorage.getItem("language") === "2" ? 2 : 1;
      axios
        .get(
          `/api/Contents/GetListByPageIdAndLanguage?id=9&language=${languageCode}`
        )
        .then((result) => {
          this.getCommunications();
          if (result.data.success) {
            // console.log("BURADA LOGA BAK");
            //    console.log(result.data.data);
            this.allDatas = result.data.data;
            this.splitData(result.data.data);
            //   console.log(this.allDatas[0]);
          } else {
            console.error("API yanıtında başarısızlık:", result.data.message);
          }
        })
        .catch((error) => {
          console.error("Catch'e düştü", error);
        });
    },
    splitData(myData) {
      myData.forEach((item) => {
        //  console.log("fdgdfgd : ",item)
        const componentId = item.componentId;
        if (!this.groupedData[componentId]) {
          this.groupedData[componentId] = [];
        }
        this.groupedData[componentId].push(item);
      });
      this.imgDataUrl = this.groupedData[19][0].image;
      //   console.log("gropudata", this.groupedData);
    },
    getCommunications() {
      const languageCode = localStorage.getItem("language") === "2" ? 2 : 1;
      axios
        .get(`/api/Informations/GetListByLanguage?language=${languageCode}`)
        .then((result) => {
          if (result.data.success) {
            this.AdressData = result.data.data;
            //   console.log(this.AdressData)
          } else {
            console.error("API yanıtında başarısızlık:", response.data.message);
          }
        })
        .catch((error) => {
          console.error("Catch'e düştü", error);
        });
    },

    getTabTitle(tabKey) {
      return localStorage.getItem("language") === "2"
        ? this.getEnglishText(tabKey)
        : this.getDefaultText(tabKey);
    },
    getEnglishText(tabKey) {
      switch (tabKey) {
        case "ERGİTME":
          return "MELTING";
        case "ALAŞIM":
          return "ALLOY";
        case "DEGAZİNG ÜNİTESİ":
          return "DEGASSING UNIT";
        case "DÖKÜM":
          return "CASTING";
        case "HOMOJENİZASYON, NORMALİZASYON":
          return "HOMOJIZATION, NORMALIZATION";
          case "KESİM ve AMBALAJ":
          return "CUTTING AND PACKAGING";
        default:
          return tabKey; // If no translation is available, return the original tab key
      }
    },
    getDefaultText(tabKey) {
      return tabKey; // Use the tab key as the default text
    },
  },
};
</script>
<style scoped>
.tabButton {
  font-family: "Prompt", sans-serif;
  border: 1px solid;
  background-color: #0725419a;
}
.aboutImg {
  width: 100%;
  height: 700px;
  object-fit: cover;
  object-position: 50% 50%;
}

@media (max-width: 1500px) {
  .nav-pill-secondary {
    margin-left: 0px;
    margin-top: 20px;
  }
  .aboutImg {
    width: 100%;
    height: auto;
    object-fit: cover;
    object-position: 50% 50%;
  }
}
</style>
