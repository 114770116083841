<template>
  <b-row>
    <div class="container ikincikisim">
      <b-row>
        <b-card class="aboutCard">
          <b-row>
            <!-- swiper -->
            <b-col md="5">
              <b-carousel id="carousel-crossfade" controls indicators fade>
                <b-carousel-slide
                  v-for="(slide, index) in homoSwiperData"
                  :key="index"
                  :img-src="slide.image"
                >
                </b-carousel-slide>
              </b-carousel>
            </b-col>

            <b-col md="7">
              <div class="carousel-text">
                <br />
                <h2 class="display-4 aboutTitle mb-2">
                  {{ homoData[0].title}}
                </h2>
                <hr />
                <div v-html="homoData[0].text"></div>
              </div>
            </b-col>
          </b-row>
        </b-card>
      </b-row>
    </div>
  </b-row>
</template>
<script>
import { BImg, BRow, BCol, BCard,BCarousel, BCarouselSlide } from "bootstrap-vue";

export default {
  components: {
    BImg,
    BRow,
    BCol,
    BCard,
    BCarousel,
    BCarouselSlide,
  },
  props: {
    homoData: Array,
    homoSwiperData:Array
  },
  data() {
    return {
      swiperOptions: {
        effect: "fade",
        spaceBetween: 30,
        autoplay: {
          delay: 3000,
          disableOnInteraction: false,
        },
        pagination: {
          el: ".swiper-pagination",
        },
      },
    };
  },
};
</script>
<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;500&display=swap"); /* Navbar için stil */
.aboutTitle {
  font-family: "Roboto", sans-serif;
  color: white;
  text-align: center;
}
.ikincikisim {
  text-align: left;
  font-family: "Prompt", sans-serif;
  font-size: 16px;
}
.aboutCard {
  background-color: transparent !important;
  border: none !important;
  color: white;
}
@media (max-width: 767px) {
  .aboutTitle {
    font-size: 35px;
  }
}
</style>
