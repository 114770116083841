<template>
  <footer>
    <hr />
    <div class="container">
      <b-col md="12"></b-col>
      <b-row>
        <b-col md="12">
          <b-row>
            <b-col md="4"  v-if="AdressData && AdressData[0]">
              <b-card class="text-left bg-transparent customCard">
                <b-card-title class="cardTitle">{{ AdressData[0].location }}</b-card-title>
                <b-card-text>{{ AdressData[0].address }}</b-card-text>
                <b-card-text>
                  <a class="link" >
                    <feather-icon icon="PhoneIcon" /> {{ AdressData[0].phone }}
                  </a></b-card-text
                >
                <b-card-text>
                  <a class="link">
                    <feather-icon icon="MailIcon" />
                    {{ AdressData[0].email }}
                  </a></b-card-text
                >
              </b-card>
            </b-col>
            <b-col md="4">
              <b-row>
                <b-col md="12">
                  <b-col md="4"></b-col>
                  <b-col md="4">
                    <b-link @click="scrollToTop">
                      <b-img
                        src="@/assets/images/logo/YBMLogo.png"
                        class="logo-img mt-2 mb-3 logoStyle"
                        width="220"
                        height="100"
                      >
                      </b-img></b-link
                  ></b-col>
                  <b-col md="4"></b-col>
                </b-col>
              </b-row>
            </b-col>
            <b-col md="4"  v-if="AdressData && AdressData[1]">
              <b-card class="text-left bg-transparent customCard">
                <b-card-title class="cardTitle"> {{ AdressData[1].location }} </b-card-title>
                <b-card-text>{{ AdressData[1].address }}</b-card-text>
                <b-card-text>
                  <a class="link" >
                    <feather-icon icon="PhoneIcon" /> {{ AdressData[1].phone }}
                  </a></b-card-text
                >
                <b-card-text>
                  <a class="link">
                    <feather-icon icon="MailIcon" />
                    {{ AdressData[1].email }}
                  </a></b-card-text
                >
              </b-card>
            </b-col>
            <!-- <b-button
              variant="linkedin"
              class="btn-icon linkedin-btn"
              :href="linkedinProfile || '#'"
              target="_blank"
              size="lg"
            >
              <feather-icon icon="LinkedinIcon" />
            </b-button> -->
          </b-row>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="5"></b-col>
        <b-col md="2">
          <router-link to="/kvkk">
            <p class="kvkkClass mb-2 ml-0" style="text-align: center">
             {{ kvkkText }}
            </p>
          </router-link></b-col
        >
        <b-col md="5"></b-col>
      </b-row>
    </div>
  </footer>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BImg,
  BLink,
  BCard,
  BTab,
  BTabs,
  BCardText,
  BCardTitle,
} from "bootstrap-vue";
//import axios from "axios";

export default {
  name: "Footer",
  components: {
    BButton,
    BCol,
    BRow,
    BImg,
    BLink,
    BCard,
    BTab,
    BTabs,
    BCardText,
    BCardTitle,
  },
  props: {
    AdressData: Array,
  },
  data() {
    return {
      error: null,
      errorMsg: "",
      submitting: false,
      img: "",
      linkedinProfile: "",
      kvkkText:"KVKK Bilgilendirme Metinleri"
    };
  },
  mounted() {
    const language = localStorage.getItem("language");
    if (language && language === "2") {
      // Dil verisi 2 ise İngilizce metni kullan
      this.kvkkText = "GDPR Information Texts"; // İngilizce metni
    }
  },
  methods: {
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth", // Animasyonlu kaydırma
      });
    },
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Abel&family=Dancing+Script&family=Prompt:wght@300&display=swap");

.cardTitle {
  color: black;
}
.customCard {
  box-shadow: none;
  margin-bottom: 0;
}
a {
  color: black;
}
.link{
  color:black
}
.logoStyle {
  margin-left: 100px;
}

.kvkkClass {
  margin-bottom: 20px;
}
@media (max-width: 1024px) {
  .logoStyle {
    margin-left: 20px;
  }
}
.linkedin-btn {
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 15px;
}
.linkedin-btn:hover {
  background-color: grey;
  transition: 0.5s;
}
</style>
<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
